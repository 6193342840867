<template>
    <div>
        <!-- Banner -->
        <base-inner-banner
            :banner-image="pageBanner"
            :is-custom-banner="true"
            :banner="banner"
        ></base-inner-banner>
        <!-- Banner -->

        <div class="content-wrapper">
            <div class="container">
                <!-- Page Head -->
                <base-page-head
                    :page-head="pagehead"
                    :show-profile-details="true"
                    :user-data="userData"
                    :user-type="userType"
                ></base-page-head>
                <!-- Page Head -->

                <!-- Page Contents -->

                <div class="page-contents Badges">
                    <div class="sidenav-wrapper">
                        <aside class="left-col">
                            <account-nav></account-nav>
                        </aside>
                        <div class="right-col">
                            <div class="userBadges">
                                <h2 class="page__title">
                                    {{ pagehead.title }}
                                </h2>
                                <ViewBadges :badges="badges" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import imgMedium from '@/assets/images/banners/ideas-submit-banner-1200x900.png'
import imgLarge from '@/assets/images/banners/ideas-submit-banner-1600x900.png'
import imgBig from '@/assets/images/banners/ideas-submit-banner-1920x600.png'
import { SettingsHelper } from '@/common/crud-helpers/settings'
import { UsersHelper } from '@/common/crud-helpers/users'
import ViewBadges from '@/modules/users/components/badges/ViewBadges.vue'
import cloneDeep from 'lodash/cloneDeep'
import { mapGetters } from 'vuex'

export default {
    components: {
        ViewBadges,
    },
    data() {
        return {
            pageBanner: {
                imgBig: imgBig,
                imgLarge: imgLarge,
                imgMedium: imgMedium,
            },
            banner: '',
            pagehead: {
                title: '',
                subtitle: '',
                shortdes: '',
            },
            userData: {},
            userType: '',
            badges: [],
        }
    },
    async created() {
        if (!this.dashboardSettings || !this.dashboardSettings.dashboard_tabs) {
            await SettingsHelper.getDashboardSettings()
        } else if (
            this.dashboardSettings &&
            this.dashboardSettings.dashboard_tabs
        ) {
            this.setTitleAndDescription()
        }
        this.setUserType()
        await UsersHelper.getBadgesList()
    },
    methods: {
        setUserType() {
            if (this.userAuth.id) {
                this.userData = cloneDeep(this.userAuth)
                if (this.userAuth.is_mentor) {
                    this.userType = 'mentor'
                } else {
                    this.userType = 'user'
                }
            }
        },
        setTitleAndDescription() {
            let activeTab = this.dashboardSettings.dashboard_tabs.filter(tab =>
                tab.value.url.includes(this.$route.name),
            )[0]
            this.banner = activeTab.value.banner
            this.pagehead.title = activeTab.value[`title_${this.$i18n.locale}`]
            this.pagehead.shortdes =
                activeTab.value[`description_${this.$i18n.locale}`]
        },
    },
    computed: {
        ...mapGetters(['userAuth', 'dashboardSettings', 'badgesList']),
    },
    watch: {
        dashboardSettings() {
            this.setTitleAndDescription()
        },
        badgesList() {
            this.badges = this.badgesList
        },
    },
}
</script>
<style lang="scss" scoped>
/deep/ {
    .sidenav-wrapper {
        margin-top: 0;
        .right-col {
            @media screen and (min-width: 1601px) {
                padding-top: 130px;
            }
            @media screen and (max-width: 1600px) {
                padding-top: 130px;
            }
            @media screen and (max-width: 1500px) {
                padding-top: 150px;
            }
            @media screen and (max-width: 1399px) {
                padding-top: 150px;
            }
            @media screen and (max-width: 1025px) {
                padding-top: 110px;
            }
            @media screen and (max-width: 991px) {
                padding-top: 115px;
            }
            @media screen and (max-width: 934px) {
                padding-top: 80px;
            }
            @media screen and (max-width: 767px) {
                padding-top: 120px;
            }
            @media screen and (max-width: 500px) {
                padding-top: 53px;
            }
            /deep/ {
                @media screen and (max-width: 991px) {
                    .page__title {
                        padding-left: 15px !important;
                    }
                }
            }
            ::after {
                display: none;
            }
        }
    }
    .content-wrapper {
        padding-top: 17.75rem;
        @media screen and (max-width: 767px) {
            padding-top: 11.2rem;
        }
        @media screen and (max-width: 500px) {
            padding-top: 6.5rem;
        }
        .page__head {
            min-height: auto;
            @media screen and (max-width: 767px) {
                min-height: 126px !important;
            }
        }
    }
    .page-contents {
        padding-top: 3rem;
    }
}

.ar {
    .content-wrapper {
        .page-contents {
            @media screen and (min-width: 1400px) {
                padding-top: 4rem;
            }
            @media screen and (max-width: 991px) {
                padding-top: 1rem;
            }
        }
        .Badges {
            .sidenav-wrapper {
                .right-col {
                    /deep/ {
                        .tabs {
                            &.ideas-tabs {
                                padding-top: 0 !important;
                                @media screen and (max-width: 1600px) {
                                    padding-top: 1rem !important;
                                }
                            }
                        }
                    }
                    @media screen and (max-width: 991px) {
                        padding-top: 54px;
                    }
                    @media screen and (max-width: 767px) {
                        padding-top: 59px;
                    }
                    @media screen and (max-width: 575px) {
                        padding-top: 7px;
                    }
                    @media screen and (max-width: 500px) {
                        padding-top: 40px;
                    }
                    /deep/ {
                        @media screen and (max-width: 991px) {
                            .page__title {
                                padding-right: 15px !important;
                                padding-left: 0 !important;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
