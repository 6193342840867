<template>
    <div class="view-badges">
        <div class="badges-block" v-if="badges.length">
            <div
                class="badges-block--badge"
                v-for="(ideaBadge, i) in badges"
                :key="i"
            >
                <img loading="lazy" :src="ideaBadge.badge.img" alt="" />
                <p class="title" v-html="ideaBadge.badge.title"></p>
                <p class="description" v-html="ideaBadge.badge.description"></p>
                <div class="button" v-if="!isMobileScreen()">
                    <a
                        class="btn btn-primary"
                        :href="shareLinkedIn(ideaBadge.badge.img)"
                        target="_blank"
                        v-tooltip="{
                            content: 'Share to LinkedIn',
                            trigger: 'hover click',
                        }"
                    >
                        <img
                            class="icon"
                            src="../../../../assets/images/svg/white-share-icon.svg"
                    /></a>
                    <!-- <a
                        class="btn btn-primary"
                        :href="shareLinkedIn(ideaBadge.badge.img)"
                        target="_blank"
                    >
                        <img
                            class="icon"
                            src="../../../../assets/images/svg/share-icon-bg-black.svg"
                    /></a> -->
                </div>
            </div>
        </div>
        <h2 v-else class="view-badges--emptyText">
            {{ $t('No Badges Earned Yet') }}
        </h2>
    </div>
</template>

<script>
export default {
    props: {
        badges: {
            type: Array,
            default: () => [],
        },
    },
    methods: {
        shareLinkedIn(badgeLink) {
            return `https://www.linkedin.com/feed/?shareActive&mini=true&text=I just received a new badge on %23Zainiac ${badgeLink}`
        },
        isMobileScreen() {
            return window.innerWidth < 768
        },
    },
}
</script>

<style lang="scss">
.vue-tooltip-theme {
    .tooltip-inner {
        font-family: $primary-font !important;
        background: transparent;
        color: black;
        border-radius: 16px;
        border: 1px solid black;
    }
}
.ar {
    .vue-tooltip-theme {
        .tooltip-inner {
            font-family: $arabic-font !important;
        }
    }
}
</style>
<style lang="scss" scoped>
.view-badges {
    padding-left: 4rem;
    @media screen and (max-width: 991px) {
        padding-left: 1.125rem;
    }
    .badges-block {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        &--badge {
            max-width: 400px;
            background-color: white;
            padding: 1rem 2rem 1rem 2rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: 10px;
            img {
                margin: auto;
                width: 240px;
                height: 240px;
                @media screen and (max-width: 375px) {
                    width: 200px;
                    height: 200px;
                }
            }
            .title {
                /deep/ p {
                    font-size: 2rem;
                    font-weight: bolder;
                    margin: 0 0 0 0;
                }
            }
            .description {
                /deep/ p {
                    text-align: center;
                    font-size: 1rem;
                }
            }
            .button {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                a {
                    margin: 0px 8px 0px 8px;
                    padding: 7px 5px;
                    font-size: 17px !important;
                    .icon {
                        padding: 7px;
                        width: 40px;
                        height: 40px;
                    }
                }
                .btn-primary {
                    min-width: 28%;
                }
                @media screen and (max-width: 575px) {
                    a {
                        font-size: 16px !important;
                    }
                }
                button {
                    background: black;
                }
            }
        }
    }
    &--emptyText {
        text-align: center;
        margin-top: 40px;
    }
}
.ar {
    .view-badges {
        padding-right: 4rem;
        @media screen and (max-width: 991px) {
            padding-right: 1.125rem;
        }
    }
}
</style>
